import styled from "styled-components";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";

export const HeaderSection = styled("header")`
  padding: 1rem 0.5rem;

  .ant-row-space-between {
    align-items: center;
    text-align: center;
  }

  @media screen and (max-width: 767px) {
    background-color: #FFFFFF;
    border-bottom: 0.5px solid #E9E9E9;
    padding: 2.666rem 0.5rem;

    .header-logo {
      width: 20vw;
      height: auto;
    }
  }
`;

export const LogoContainer = styled(Link)`
  display: flex;
`;

export const NavLink = styled("div")`
  display: inline-block;
  text-align: center;
`;

export const ContactWrapper = styled("div")<any>`
  cursor: pointer;
  width: ${(p) => (p.width ? "100%" : "11.0rem")};
  font-weight: 700;
  text-align: center;
  border-radius: 1.25rem;
  display: inline-block;
`;

export const Burger = styled("div")`
  @media screen and (max-width: 767px) {
    display: block;
  }

  display: none;

  svg {
    fill: #0F0B01;
    width: 24px;
    height: 24px;
  }
`;

export const NotHidden = styled("div")`
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const Menu = styled("h5")`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;

  
  @media screen and (max-width: 899px) {
    font-size: 2.5rem;
  }
`;

export const CustomNavLinkSmall = styled(NavLink)`
  font-size: 1.2rem;
  color: #0F0B01;
  margin: 0;
  
  @media screen and (min-width: 768px) {
    &:first-of-type {
      margin-right: 2.4rem;
    }
  }

  @media screen and (max-width: 767px) {
    &:first-of-type {
      margin-bottom: 2.4rem !important;
    }
  }
`;

export const Label = styled("span")`
  font-weight: 500;
  color: #404041;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const Outline = styled(MenuOutlined)<any>`
  font-size: 2.2rem;
`;
