import styled from "styled-components";

export const StyledContainer = styled("div")<any>`
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 10.0rem;
  border-top: ${(p) => (p.border ? ".1rem solid #CDD1D4" : "")};

  @media screen and (max-width: 899px) {
    padding: 0 2.666rem;

    &:has(div#newsletter) {
      padding: 0;
    } 
  }
`;
