import styled from "styled-components";

export const ModalContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 5.2rem 8.0rem;

  h1 {
    color: #212121;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 3.8rem;
  }

  p {
    color: #474747 !important;
    font-size: 1.6rem !important;
    font-weight: 400 !important;
    line-height: 2.6rem !important;

    @media screen and (max-width: 899px) {
      font-size: 2.333rem !important;
    }
  }
`;

export const Animation = styled("div")<any>`
  background-image: url("${(p) => p.src}");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 15.0rem;
  height: 15.0rem;
  margin-bottom: 4.0rem;
`;