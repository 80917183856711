import { AddNewTagPaper, AddNewTagPopper, StyledList, StyledListItem } from "./styles";

type Props = {
    type: string;
    show: boolean;
    value: {
        text: string;
    };
};

const AddNewTag = ({type, show, value }: Props) => {
    if (!show) return null;

    const handleClick = () => {
        document.querySelector("input#req-access-autocomplete")?.dispatchEvent(new KeyboardEvent('keydown', {
            code: 'Enter',
            key: 'Enter',
            bubbles: true
        }))
    };

    return (
        <AddNewTagPopper className="MuiAutocomplete-popper MuiPopper-root">
            <AddNewTagPaper className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiAutocomplete-paper">
                <StyledList className="MuiAutocomplete-listbox">
                    <button onClick={ handleClick }>
                        <StyledListItem tabIndex={-1} className="MuiAutocomplete-option">
                            <strong>{ value.text }</strong>&nbsp;not found&nbsp;<strong>+ Add { type === "talent" ? "Talent category" : "Business type" }</strong>
                        </StyledListItem>
                    </button>
                </StyledList>
            </AddNewTagPaper>
        </AddNewTagPopper>
    );
};

export default AddNewTag;
