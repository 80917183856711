import { Bounce } from "react-awesome-reveal";
import { Span } from "../../components/NewsletterForm/styles";
import { Close } from "@mui/icons-material";

type InputErrorProps = {
    error?: string;
};

const InputError = ({error}: InputErrorProps) => {
    if (error) {
        return (
            <Bounce>
                <Span><Close /> {error}</Span>
            </Bounce>
        );
    }
    return null;
};

export default InputError;