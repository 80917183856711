import { Button, Col, Modal } from "antd";
import { withTranslation } from "react-i18next";
import { RequestAccessProps } from "./types";
import { useState } from "react";
import Input from "../../common/Input";
import OnboardingCollapse from "../OnboardingCollapse";
import { Tag } from "../SelectTags";
import InputError from "../../common/InputError";
import fetch from 'isomorphic-fetch';

type GraphQLResponse = {
    data?: any;
    errors?: [{
        message: string
    }]
};

const QueryRequestAccess = async (
    role: string,
    email: string,
    firstName: string,
    lastName: string,
    tags: Tag[],
    businessName: string,
    businessTags: Tag[],
    pilotCheckbox: boolean,
) => {
    const err: {[key: string] : string} = {};

    email = email.trim();
    
    if (!email) {
        err["email"] = "This field is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        err["email"] = "Invalid email address. Please enter a valid email address (e.g. name@example.com)";
    }
    if (role !== "artist" && role !== "business") {
        err["role"] = "Please select a role";
    }
    if (role === "artist") {
        if (firstName.trim().length === 0) {
            err["firstName"] = "This field is required";
        }
        if (lastName.trim().length === 0) {
            err["lastName"] = "This field is required";
        }
        if (tags.length === 0) {
            err["tags"] = "Please enter at least one talent that portrays you professionally and personally";
        }

        firstName = firstName.trim().charAt(0).toUpperCase() + firstName.trim().slice(1)
        lastName = lastName.trim().charAt(0).toUpperCase() + lastName.trim().slice(1)
    } else {
        if (businessName.trim().length === 0) {
            err["businessName"] = "This field is required";
        }
        if (businessTags.length === 0) {
            err["businessTags"] = "Business type required for personalized experience";
        }

        businessName = businessName.trim().charAt(0).toUpperCase() + businessName.trim().slice(1)
    }

    if (role === "business" && pilotCheckbox) {
        businessTags.push({"text": "Pilot"})
    }
    console.log(businessTags);

    if (Object.keys(err).length !== 0) {
        return err;
    }

    const categories = tags.map(t => t.text);
    const businessCategory = businessTags.length > 0 ? businessTags[0].text : "";

    const response = await fetch(process.env.REACT_APP_GRAPHQL_URL!, {
        credentials: 'include',
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            query: `
                mutation {
                    requestAccess(
                        role: "${ role }",
                        email: "${ email }",
                        firstName: "${ firstName }",
                        lastName: "${ lastName }",
                        categories: ${ JSON.stringify(categories) },
                        businessName: "${ businessName }",
                        businessCategory: "${ businessCategory }"
                    )
                }
            `
        })
    });
    
    const gr: GraphQLResponse = await response.json();
    
    if (gr.errors) {
        throw gr.errors[0].message;
    }

    return gr.data?.requestAccess;
};

const RequestAccessModal = ({ open, setOpen, email, handleChangeEmail, setOpenConfirmation, t, pilotProgram = false}: RequestAccessProps) => {
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [emailLocal, setEmailLocal] = useState("");
    const [tags, setTags] = useState<Tag[]>([]);
    const [businessTags, setBusinessTags] = useState<Tag[]>([]);
    const [role, setRole] = useState("");
    const [error, setError] = useState<{[key: string] : string}>({});
    const [pilotCheckbox, setPilotCheckbox] = useState(false);

    const handleChangeRole = (key: string[]) => {
        setError({});
        
        if (key.length === 0) {
            setRole("");
            return;
        }
        if (key[0] === "1") {
            setRole("artist");
            setBusinessName("");
            setBusinessTags([]);
            return;
        }

        setRole("business");
        setFirstName("");
        setLastName("");
        setTags([]);
    };

    const handleChangeFirstName = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        setFirstName(e.target.value);
    };

    const handleChangeLastName = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        setLastName(e.target.value);
    };

    const handleChangeBusinessName = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        setBusinessName(e.target.value);
    };

    const handleChangeEmailLocal = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        setEmailLocal(e.target.value);
    };

    const handleChangeTags = (v: Tag[]) => {
        setTags(v);
    };

    const handleChangeBusinessTags = (v: Tag[]) => {
        setBusinessTags(v);
    };

    const handleOk = async () => {
        setLoading(true);
        setError({});

        await QueryRequestAccess(role, email || emailLocal, firstName, lastName, tags, businessName, businessTags, pilotCheckbox)
        .then(res => {
            setLoading(false);
            console.log("res",res)
            if (typeof res === "object") {
                setError(res)
                return;
            }

            setOpen(false);
            handleCancel();
            showConfirmationModal();
        }).catch(error => {
            let err: {[key: string] : string} = {};

            if (error.includes("Member In Compliance State") || error.includes("Member Exists") || error.includes("Forgotten Email Not Subscribed")) {
                err["email"] = "This email has been already used to request access.";
            }

            setLoading(false);
            setError(err);
        });
    };
    
    const handleCancel = async () => {
        setOpen(false);
        setRole("");
        setFirstName("");
        setLastName("");
        setTags([]);
        setBusinessName("");
        setBusinessTags([]);
        setError({});
    };

    const handlePilotCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPilotCheckbox(e.target.checked);
    }

    const showConfirmationModal = async () => {
        setOpenConfirmation(true);
    };
    
    return (
        <>
            <Modal
                destroyOnClose
                title={t("Request access to join Weavii")}
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button type="primary" key="submit" loading={loading} onClick={handleOk}>
                    { t("Request Access") }
                    </Button>,
                    <InputError key={"general"} error={error["general"]} />
                ]}>
                <Col xs={24} style={{marginBottom: "4.0rem"}}>
                    <p>Email address</p>
                    <Input 
                        type="text"
                        name="email"
                        placeholder="Enter your email"
                        value={email || emailLocal}
                        onChange={handleChangeEmail || handleChangeEmailLocal} />
                    <InputError key={"email"} error={error["email"]} />
                </Col>
                <Col xs={24}>
                    <p>Join as</p>
                </Col>
                <Col xs={24}>
                    <span className='subtitle'>{t("Select the role that best describes you")}</span>
                    <InputError key={"role"} error={error["role"]} />
                </Col>
                <Col xs={24}>
                    <OnboardingCollapse
                        firstName={firstName}
                        lastName={lastName}
                        businessName={businessName}
                        tags={tags}
                        businessTags={businessTags}
                        handleChangeRole={handleChangeRole}
                        handleChangeFirstName={handleChangeFirstName}
                        handleChangeLastName={handleChangeLastName}
                        handleChangeBusinessName={handleChangeBusinessName}
                        handleChangeTags={handleChangeTags}
                        handleChangeBusinessTags={handleChangeBusinessTags}
                        handlePilotCheckbox={handlePilotCheckbox}
                        error={error}
                        pilotProgram={pilotProgram}
                    />
                </Col>
            </Modal>
        </>
    );
};

export default withTranslation()(RequestAccessModal);