import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterSection = styled("footer")`
  background: #0F0B01;
  padding: 2.5rem 0;
  
  .social {
    margin: 4.0rem 0;
  }

  .term-of-use, .term-of-use + div {
    font-size: 1.6rem;
    text-align: right;

    @media screen and (max-width: 899px) {
      font-size: 2.333rem;
      line-height: 140%;
    }

    @media screen and (max-width: 767px) {
      text-align: center;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 1023px) {
    .footer-logo {
      max-width: 150px;
      width: 28vw;
      height: auto;
    }
  }

  @media screen and (max-width: 767px) {
    .bottom-content {
      margin-top: 6.666rem;
      flex-direction: column-reverse;
      align-content: center;
    }
  }

  @media screen and (max-width: 767px) {
    .social-col {
      order: 1;
    }

    .social {
      margin: 3rem 0;
    }
  }
`;

export const FooterContainer = styled("div")`
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 10.0rem;
  border: none;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 899px) {
    padding: 0 2.666rem;
  }
`

export const Title = styled("h4")`
  color: #F7F5F2;
  font-size: 2.1rem;
  font-weight: 700;
  line-height: 3.0rem;
  text-transform: capitalize;

  @media screen and (max-width:  899px) {
    font-size: 2.666rem;
    line-height: 160%
  }
`;

export const NavLink = styled(Link)`
  color: #F7F5F2;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin-bottom: 0.625rem;
  transition: filter 0.2s ease;

  &:hover,
  &:active,
  &:focus {
    color: #F7F5F2;
    filter: brightness(90%);
  }

  @media screen and (max-width: 899px) {
    font-size: 2.333rem;
  }
`;

export const ModalNavLink = styled("div")`
  color: #F7F5F2;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 140%;
  display: block;
  margin-bottom: 0.625rem;
  transition: filter 0.2s ease;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    color: #F7F5F2;
    filter: brightness(90%);
  }

  @media screen and (max-width: 899px) {
    font-size: 2.333rem;
    line-height: 160%;
  }
`;

export const LogoContainer = styled("div")`
  display: flex;
  position: relative;
`;

export const Para = styled("p")`
  color: #F7F5F2;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 140%;
  margin: 0;

  @media screen and (max-width: 899px) {
      font-size: 2.333rem;
      line-height: 140%;
  }
`;
