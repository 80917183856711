import styled from "styled-components";

export const StyledInput = styled("input")`
  background: #F5F5F5;
  border: 0;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.6rem;

  &:hover,
  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }

  @media screen and (max-width: 899px) {
    font-size: 2.333rem;
  }
`;
