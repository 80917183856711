import styled from "styled-components";

export const AddNewTagPopper = styled("div")<any>`
  position: relative;
  top: -11px;
  margin-bottom: -54px;
  left: 1px;
  width: calc(100% - 1px);
  z-index: 1;
`;

export const AddNewTagPaper = styled("div")<any>`
  border-radius: 0.4rem;
  box-shadow: .0rem .4rem 3.2rem -0.4rem rgba(33, 33, 33, 0.14), .0rem .1rem .2rem .1rem rgba(33, 33, 33, 0.04);
  background-color: #FFFFFF;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 400;
  line-height: 1.5;
  overflow: auto;
`;

export const StyledList = styled("ul")<any>`
  list-style: none;
  margin: 0;
  padding: 8px 0;
  max-height: 40vh;
  overflow: auto;
  position: relative;

  @media screen and (max-width: 599px) {
    padding: 18px 0;
  }
  
  button {
    background: transparent;
    border: none;
    width: fit-content;
    height: fit-content;
    padding: 0;
    margin: 0;
  }
`;

export const StyledListItem = styled("li")<any>`
  min-height: auto;
  display: flex;
  overflow: hidden;
  -webkit-box-pack: start;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  padding: 6px 16px;

  strong {
    font-weight: 700 !important;

    &:last-of-type {
      color: #0057FF !important;

      &:hover {
        color: #548EFF !important;
      }
    }
  }
`;
