import { Col, CollapseProps } from 'antd';
import { Collapse, theme, Checkbox } from 'antd';
import { CSSProperties } from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import SelectTags from '../SelectTags';
import { Tag } from '../SelectTags';
import Input from '../../common/Input';
import InputError from '../../common/InputError';
import { withTranslation } from "react-i18next";

type OnboardingCollapseProps = {
  firstName: string,
  lastName: string,
  businessName: string,
  tags: Tag[],
  businessTags: Tag[],
  handleChangeRole: any,
  handleChangeFirstName: any,
  handleChangeLastName: any,
  handleChangeBusinessName: any,
  handleChangeTags: any,
  handleChangeBusinessTags: any,
  handlePilotCheckbox: any,
  error: any,
  pilotProgram?: boolean,
  t: any,
};

const OnboardingCollapse = ({
  firstName,
  lastName,
  businessName,
  tags,
  businessTags,
  handleChangeRole,
  handleChangeFirstName,
  handleChangeLastName,
  handleChangeBusinessName,
  handleChangeTags,
  handleChangeBusinessTags,
  handlePilotCheckbox,
  error,
  pilotProgram = false,
  t
}: OnboardingCollapseProps) => {
  const { token } = theme.useToken();

  const panelStyle = {
    background: token.colorFillAlter,
    border: 'none',
  };

  const aList: Tag[] = [
    {"text": "Musician"},
    {"text": "Vocalist"},
    {"text": "Producer"},
    {"text": "Sound engineer"},
    {"text": "Actor"},
    {"text": "Painter"},
    {"text": "Comedian"},
    {"text": "Writer"},
    {"text": "Sculptor"},
    {"text": "Illustrator"},
    {"text": "Photographer"},
    {"text": "Dancer"},
    {"text": "DJ"},
    {"text": "Film director"},
    {"text": "Performance artist"},
    {"text": "Choreographer"},
    {"text": "Animator"},
    {"text": "Printmaker"},
    {"text": "Visual artist"},
    {"text": "Creative director"},
    {"text": "Videomaker"}
  ];

  
  const bList: Tag[] = [
    {"text": "Event organizer"},
    {"text": "Booking agent"},
    {"text": "Hospitality sector"},
    {"text": "Ad agency"},
    {"text": "Corporate events"},
    {"text": "Media company"},
    {"text": "Music festival"},
    {"text": "Film studio"},
    {"text": "Promoter"},
    {"text": "Wedding services"},
    {"text": "Theater group"},
    {"text": "Music venue"},
    {"text": "Museum"},
    {"text": "Talent agency"},
    {"text": "Art gallery"},
    {"text": "Radio station"}
  ];

  const artistOnboardingForm = (
    <>
      <Col className='modal-name-input' xs={11}>
        <p>First name</p>
        <Input
          type="text"
          name="firstName"
          placeholder="Enter your first name"
          value={firstName}
          onChange={handleChangeFirstName} />
        <InputError key={"firstName"} error={error["firstName"]} />
      </Col>
      <Col className='modal-name-input' xs={11} push={2}>
        <p>Last name</p>
        <Input
          type="text"
          name="lastName"
          placeholder="Enter your last name"
          value={lastName}
          onChange={handleChangeLastName} />
        <InputError key={"lastName"} error={error["lastName"]} />
      </Col>
      <Col xs={24}>
        <p>Talent category</p>
      </Col>
      <Col xs={24}>
        <span className='subtitle'>Select the talent categories that portray you professionally and personally.</span>
      </Col>
      <Col xs={24}>
        <SelectTags
          type="talent"
          values={tags}
          setValues={(v: Tag[]) => handleChangeTags(v)}
          tagList={aList}
          placeholder="e.g. Singer, Musician, Sound engineer..."
          />
        <InputError key={"tags"} error={error["tags"]} />
      </Col>
      <Col xs={24}>
        <p>Suggestion:</p>
      </Col>
      <Col xs={24}>
        <span className='subtitle'>Musician, Painter, Actor, Dancer, Singer, Illustrator, Comedian, ...</span>
      </Col>
    </>
  );

  const businessOnboardingForm = (pilotProgram: boolean) => (
    <>
      <Col className='modal-name-input' xs={24}>
        <p>Business name</p>
        <Input
          type="text"
          name="businessName"
          placeholder="Enter your business name"
          value={businessName}
          onChange={handleChangeBusinessName} />
        <InputError key={"businessName"} error={error["businessName"]} />
      </Col>
      <Col xs={24}>
        <p>Business type</p>
      </Col>
      <Col xs={24}>
        <span className='subtitle'>Add your business type to help us personalize your experience.</span>
      </Col>
      <Col xs={24}>
        <SelectTags
          type="gigType"
          values={businessTags}
          setValues={(v: Tag[]) => handleChangeBusinessTags(v)}
          tagList={bList}
          placeholder="e.g. Event organizer, Promoter, Art gallery..."
        />
        <InputError key={"businessTags"} error={error["businessTags"]} />
      </Col>
      { pilotProgram ? <>
      <Col xs={24}>
        <span className='subtitle'>{t("Please check the box below if you are interested in becoming a pilot partner to help Weavii develop solutions that meet the needs of your industry")}</span>
      </Col>
      <Col xs={24}>
        <Checkbox onChange={handlePilotCheckbox}>{t("I am interested in becoming a pilot partner ")}</Checkbox>
      </Col> 
      </> : <></> }
    </>
  );

  const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = (panelStyle) => [
    {
      key: '1',
      label: (
        <>
          <h6>Artist</h6>
          <p>I am an artist looking to network with other artist.</p>
          <SvgIcon src="onboarding-artist.svg" width='150px' height='150px' />
        </>
      ),
      children: <>{artistOnboardingForm}</>,
      style: panelStyle,
      showArrow: false
    },
    {
      key: '2',
      label: (
        <>
          <h6>Business</h6>
          <p>I am a business looking to hire artists.</p>
          <SvgIcon src="onboarding-business.svg" width='150px' height='150px' />
        </>
      ),
      children: <>{businessOnboardingForm(pilotProgram)}</>,
      style: panelStyle,
      showArrow: false
    }
  ];

  return (
    <Collapse
      accordion
      destroyInactivePanel
      bordered={false}
      style={{ background: token.colorBgContainer }}
      items={getItems(panelStyle)}
      onChange={(key) => {handleChangeRole(key)}}
    />
  );
};

export default withTranslation()(OnboardingCollapse);