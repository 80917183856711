import { withTranslation } from "react-i18next";
import { StyledInput } from "./styles";
import { InputProps } from "../types";

const Input = ({ type, name, placeholder, value, onChange, t }: InputProps) => (
    <StyledInput
      type={type}
      placeholder={t(placeholder)}
      name={name}
      value={value}
      onChange={onChange}
      autoComplete="true"
    />
);

export default withTranslation()(Input);
