import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { Button } from "../../common/Button";
import RequestAccessModal from "../RequestAccessModal";
import { Close } from "@mui/icons-material";

import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  CustomNavLinkSmall,
  Label,
  Outline
} from "./styles";
import ConfirmationModal from "../ConfirmationModal";
import { PngIcon } from "../../common/PngIcon";

const Header = ({ t }: any) => {
  const [open, setOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const showModal = () => {
    setOpen(true);
  };
  
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    return (
      <>
        <CustomNavLinkSmall>
          <a href="/signin"><Button className="header-sign-in-btn" color="#FFFFFF">{t("Sign In")}</Button></a>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          onClick={showModal}
        >
          <Button>{t("Request Access")}</Button>
        </CustomNavLinkSmall>
        <RequestAccessModal open={open} setOpen={setOpen} setOpenConfirmation={setOpenConfirmation} />
        <ConfirmationModal open={openConfirmation} setOpen={setOpenConfirmation} title="Request sent!"
          text="Thank you for your interest in joining Weavii! We're excited to have you onboard.
          You will receive an email from us when your request has been approved." />
      </>
    );
  };

  return (
    <HeaderSection id="header">
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <PngIcon className="header-logo" src="logo.png" width="140px" height="auto" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
              <LogoContainer to="/" aria-label="homepage">
                <PngIcon className="header-logo" src="logo.png" width="140px" height="auto" />
              </LogoContainer>
              </Col>
              <Col span={12}>
                <Close fontSize="inherit" />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
