const routes = [
  {
    path: ["/"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/summit2023"],
    exact: true,
    component: "Summit2023",
  },
  {
    path: ["/signin"],
    exact: true,
    component: "SignIn",
  },
  {
    path: ["/contact"],
    exact: true,
    component: "Contact",
  },
  {
    path: ["/feedback"],
    exact: true,
    component: "Feedback",
  },
  {
    path: ["/imprint"],
    exact: true,
    component: "Imprint",
  },
  {
    path: ["/privacy"],
    exact: true,
    component: "PrivacyPolicy",
  },
  {
    component: "PageNotFound",
  },
];

export default routes;
