import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`
    body,
    html,
    a {
        font-family: "Nunito Sans", sans-serif;
        font-weight: 400;
        font-size: 10px;

        @media screen and (max-width: 1279px) {
            font-size: 8px;
        }

        @media screen and (max-width: 1024px) {
            font-size: 7px;
        }

        @media screen and (max-width: 899px) {
            font-size: 6px;
        }
    }

    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #fff;
        overflow-x: hidden;
    }

    a:hover {
        color: #0F0B01;
    }

    input,
    textarea {
        border-radius: .4rem;
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.1s ease;
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        :focus-within {
            outline: none;
            background: none;
            box-shadow: #0F0B01 .0rem .0rem .0rem .1rem;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #0F0B01;
        font-family: "Nunito Sans", sans-serif;
        font-weight: 700;
        font-size: 4.8rem;
        line-height: 120%;
        letter-spacing: 0.02rem;
        
        @media screen and (max-width: 899px) {
            font-size: 4.0rem;
        }
    }

    p {
        color: #474747;
        font-weight: 400;
        font-size: 2.0rem;
        line-height: 3.2rem;
        
        @media screen and (max-width: 899px) {
            font-size: 2.5rem;
            line-height: 160%;
        }
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #2E186A;

        :hover {
            color: #0F0B01;
        }
    }
    
    *:focus {
        outline: none;
    }

    ::placeholder {
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.6rem;
        color: #7E7E7E;

        @media screen and (max-width: 899px) {
            font-size: 2.333rem;
        }
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 100% !important;

        .header-logo {
            width: 20vw;
            height: auto;
            padding-left: 2.2rem;
        }

        .ant-col.ant-col-12 {
            align-self: center;
            padding-right: 2.2rem;
        }
        
        .header-sign-in-btn {
            margin-top: 5.0rem;
            margin-bottom: 3.4rem;
        }

        button {
            width: 24.5rem;
        }
    }

    .ant-modal {
        width: 100% !important;
        min-width: 32.0rem !important;
        max-width: 80.0rem !important;
        height: 60vh;
        min-height: 70.0rem;
        
        @media screen and (max-width: 767px) {
            width: 100vw !important;
            height: 100vh;
            border-radius: 0;
            top: 0;
            left: 0;
            margin: 0;
            padding: 0;
        }

        p {
            font-weight: 700;
            font-size: 1.8rem;
            line-height: 2.8rem;
            color: #212121;
            margin: 0;

            @media screen and (max-width: 899px) {
                font-size: 2.333rem;
            }
        }

        input {
            padding: 1.2rem 2.0rem;
            background: #F5F5F5;
            border: 0.05rem solid #E9E9E9;
            border-radius: 1.2rem;
            margin: 1.2rem 0;

            @media screen and (max-width: 899px) {
                padding: 2.0rem 2.666rem;
            }
        }

        span.subtitle {
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 2.2rem;
            color: #7E7E7E;

            @media screen and (max-width: 899px) {
                font-size: 2.333rem;
            }
        }

        div.ant-collapse-item {
            margin: 5.0rem 0 8.0rem !important;
            
            .ant-collapse-header {
                height: fit-content;
                background: #FFFFFF;
                border: 0.05rem solid #38342C;
                border-radius: .8rem !important;
                padding: 2.0rem;
            }

            .ant-collapse-header[aria-expanded="true"] {
                padding: 1.8rem;
                background: #FEF9EC;
                border: .2rem solid #F5C642;
                
                img {
                    opacity: 1;
                    top: -5rem;

                    @media screen and (max-width: 899px) {
                        top: -2.4rem;
                    }
                }
            }

            h6 {
                font-weight: 700;
                font-size: 2.4rem;
                line-height: 3.8rem;
                color: #212121;
                margin: 0;
            }

            p {
                font-weight: 400;
                font-size: 1.6rem;
                line-height: 2.6rem;
                color: #212121;
                margin: 0;

                @media screen and (max-width: 899px) {
                    font-size: 2.333rem;
                    max-width: 80%;
                    min-height: 5.21rem;
                }
                
                @media screen and (max-width: 479px) {
                    max-width: 75%;
                }
                
                @media screen and (max-width: 374px) {
                    max-width: 70%;
                }
            }

            img {
                width: 15.0rem;
                height: 15.0rem;
                position: absolute;
                right: 0;
                top: -4.65rem;
                opacity: 0.3;

                
                @media screen and (max-width: 899px) {
                    top: -2.07rem;
                }
            }
        }
        
        div.ant-collapse-item:last-of-type {
            margin-bottom: 0 !important;
        }

        .ant-collapse-content {
            background: #FFFFFF !important;
            padding-top: 4.0rem;

            .ant-collapse-content-box {
                padding: 0 !important;
            }

            p {
                font-weight: 700 !important;
                font-size: 1.6rem !important;
                line-height: 2.6rem !important;
                color: #212121 !important;
                margin: 0 !important;

                @media screen and (max-width: 899px) {
                    font-size: 2.333rem !important;
                }
            }

            input {
                margin: 1.2rem 0;
            }
        }
    }

    .ant-modal-content, .ant-modal-header {
        width: 100%;
        background: #FFFFFF;
    }

    .ant-modal {
        .ant-modal-content {
            border-radius: 1.2rem;
            padding: 4.0rem 6.0rem 4.0rem 8.0rem;
            height: 60vh;
            min-height: 70.0rem;
            display: flex;
            flex-direction: column;
    
            @media screen and (max-width: 767px) {
                width: 100vw;
                height: 100vh;
                border-radius: 0;
                padding: 3.0rem 3.0rem 15.0rem !important;
            }
        }

        &.confirmation-modal {
            max-width: 60.0rem !important;

            .ant-modal-content {
                padding: 0;
                height: auto;
                min-height: 50.0rem;
                display: flex;
                flex-direction: column;

                .ant-modal-body {
                    padding: 0 !important;
                    align-content: center;
                    overflow: hidden;
                }

                .ant-modal-footer {
                    padding: 0 8.0rem 5.2rem;

                    button {
                        background-color: #FFFFFF;
                        border: 0.5px solid #0F0B01;

                        &:hover,
                        &:active,
                        &:focus {
                            background: #FFFFFF !important;
                        }
                    }
                }

                @media screen and (max-width: 767px) {
                    width: 100vw;
                    max-width: 100vw;
                    height: 100vh;
                    border-radius: 0;
                    padding: 0 !important;
                }
            }
        }
    }

    .ant-modal-header {
        border-radius: 1.2rem 1.2rem 0 0;

        .ant-modal-title {
            font-weight: 700;
            font-size: 3.2rem;
            line-height: 160%;
            color: #212121;
        }
    }

    .ant-modal-close {
        top: 6.0rem !important;
        right: 8.0rem !important;
        inset-inline-end: 8.0rem !important;
        width: auto !important;
        height: auto !important;
        font-weight: 700 !important;
        font-size: 2.2rem !important;
        line-height: 0 !important;
        padding: 0 !important;
        color: #212121 !important;

        @media screen and (max-width: 899px) {
            top: 4.4rem !important;
            right: 8.0rem !important;
        }

        @media screen and (max-width: 767px) {
            top: 4.4rem !important;
            right: 5.0rem !important;
        }
    }

    .ant-modal-header, .ant-modal-body, .ant-modal-footer {
        border: none;
        padding-right: 2.0rem;
    }

    .ant-modal-body {
        padding: 4.0rem 2.0rem 4.0rem 0 !important;
        height: calc(100% - 12.2rem);
        overflow-y: scroll;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
    }

    .ant-btn-primary {
        color: #0F0B01;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 2.0rem;
        background: #F5C642;
        border: none;
        border-radius: 1.2rem;
        text-shadow: none;
        box-shadow: none;
        width: 100%;
        min-width: fit-content;
        height: auto;
        padding: 15px 32px;
        transition: all 0.2s ease;
        outline: 0;

        &:hover,
        &:active,
        &:focus {
            color: #0F0B01 !important;
            background: #F5C642 !important;
            filter: brightness(90%) !important;
        }

        @media screen and (max-width: 899px) {
            font-size: 2.5rem;
        }
    }

    fieldset.MuiOutlinedInput-notchedOutline,
    input.MuiInputBase-input,
    div.MuiInputBase-root,
    div.MuiFormControl-root,
    div.MuiAutocomplete-root,
    div.MuiOutlinedInput-root,
    div.Mui-focused {
        font-size: 1.6rem !important;
        line-height: 2.6rem;
        border: none !important;
        padding: 0 !important;
        margin: 0 !important;
        outline: none !important;
        
        &:hover,
        &:active,
        &:focus {
            outline: none !important;
            border: none !important;
            box-shadow: none !important;
        }

        @media screen and (max-width: 899px) {
            font-size: 2.333rem !important;
        }
    }

    div.MuiInputBase-root {
        margin: 1.2rem 0 !important;
        padding: 1.2rem 1.2rem !important;
        background: #F5F5F5 !important;
        border: 0.05rem solid #E9E9E9 !important;
        border-radius: 1.2rem !important;
        min-height: 61px;

        &:hover,
        &:active,
        &:focus {
            border: 0.05rem solid #E9E9E9 !important;
        }

        input {
            min-height: 3.6rem;
        }
    }

    .MuiAutocomplete-endAdornment {
        top: calc(50% - 1.4rem) !important;
    }

    .MuiTooltip-tooltip {
        font-size: 1.1rem !important;
    }

    .MuiTooltip-tooltipPlacementBottom {
        top: -2.0rem;
        margin: 0 !important
    };

    .MuiAutocomplete-tag {
        max-width: calc(100% - 0.6rem) !important;
        margin: 0.2rem 0.3rem !important;

        @media screen and (max-width: 1279px) {
            max-height: 40px !important;
        }
    }

    .MuiChip-root {
        border-radius: .4rem !important;
        
        .MuiChip-label {
            font-size: 12px;
            line-height: 14px;
            padding: 4px 8px !important;
        }
    }

    .ant-collapse-content-box {
        display: flex;
        flex-wrap: wrap;
    }

    .modal-name-input {
        margin-bottom: 4.0rem;
    }

    .MuiAutocomplete-popper * {
        font-family: "Nunito Sans", sans-serif !important;
        font-size: 1.6rem !important;
        font-weight: 400 !important;
        line-height: 2.6rem !important;
        color: #212121 !important;
        margin: 0 !important;

        .MuiAutocomplete-paper {
            border-radius: 0.4rem !important;
            box-shadow: .0rem .4rem 3.2rem -0.4rem rgba(33, 33, 33, 0.14), .0rem .1rem .2rem .1rem rgba(33, 33, 33, 0.04) !important;
            background-color: #FFFFFF !important;
            transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
            font-weight: 400 !important;
            line-height: 1.5 !important;
            overflow: auto !important;

            .MuiAutocomplete-listbox {
                list-style: none !important;
                margin: 0 !important;
                max-height: 40vh !important;
                overflow: auto !important;
                position: relative !important;
    
                .MuiAutocomplete-option {
                    min-height: auto !important;
                    display: flex !important;
                    overflow: hidden !important;
                    -webkit-box-pack: start !important;
                    justify-content: flex-start !important;
                    align-items: center !important;
                    cursor: pointer !important;
                    box-sizing: border-box !important;
                    outline: 0 !important;
                    -webkit-tap-highlight-color: transparent !important;
                }
            }
        }

        @media screen and (max-width: 899px) {
            font-size: 2.333rem !important;
        }
    }
`;
