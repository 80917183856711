import { Button, Modal } from "antd";
import { ConfirmationModalProps } from "./types";

import { ModalContainer, Animation } from "./styles";

const ConfirmationModal = ({ open, setOpen, title, text }: ConfirmationModalProps) => {    
    return (
        <Modal className="confirmation-modal"
            closable={false}
            destroyOnClose
            open={open}
            footer={[
                <Button type="primary" key="close" onClick={() => setOpen(false)}>
                    Close
                </Button>
            ]}
        >
                <ModalContainer>
                    <Animation src="/img/gif/message-sent.gif" />
                    <h1>{title}</h1>
                    <p>{text}</p>
                </ModalContainer>
        </Modal>
    );
};

export default ConfirmationModal;