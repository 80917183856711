import styled from "styled-components";

export const NewsletterContainer = styled("div")`
  padding: 8.0rem 8.0rem 4.4rem;
  background-color: #0F0B01;
  background-image: url("/img/svg/newsletter-bg.svg");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  border-radius: 1.2rem;
  margin: 5.0rem 0 10.0rem;
  
  @media screen and (max-width: 899px) {
    padding: 6.666rem 2.666rem;
    margin: 6.666rem 0;
    border-radius: 0;

    p {
      font-size: 4.666rem !important;
    }
  }
`;

export const FormGroup = styled("form")`
  width: 100%;
  
  .input {
    display: flex;
    padding: .8rem;
    margin: 0 auto; 
    background: #F5F5F5;
    border: 0.05rem solid #E9E9E9;
    border-radius: 1.2rem;

    @media screen and (max-width: 899px) {
      padding: 1.333rem;
    }

    @media screen and (max-width: 679px) {
      max-width: 100%;
    }
  }
`;

export const Span = styled("span")<any>`
  display: flex;
  align-items: center;
  color: #FF4757;
  font-weight: 400;
  font-size: 1.2rem;
  height: 3.6rem;
  padding: 1.2rem 0;

  svg {
    margin-right: 0.7rem;
    width: 1.2rem;
    height: 1.2rem;
  }

  @media screen and (max-width: 679px) {
    text-align: center;
  }
`;
