import styled from "styled-components";

export const AutocompleteContainer = styled("div")`
  .select-tags-input-gigs {
    .MuiChip-root {
      background: transparent;
      padding: 0 !important;
      margin: 0 !important;
    }

    .MuiChip-label {
      font-size: 1.6rem;
      overflow: visible;

      @media screen and (max-width: 899px) {
        font-size: 2.333rem;
      }
    }

    .MuiChip-deleteIconMedium {
      display: none;
    }
  }
`;
