import styled from "styled-components";

export const StyledButton = styled("button")<any>`
  background: ${(p) => p.color || "#0F0B01"};
  color: ${(p) => (p.color ? "#0F0B01" : "#FFFFFF")};
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.0rem;
  border: 0;
  border-radius: 1.2rem;
  padding: 15px 32px;
  cursor: pointer;
  transition: filter 0.2s ease;
  box-shadow: .0rem .0rem .6rem rgba(0, 0, 0, 0.02), .0rem .2rem .4rem rgba(0, 0, 0, 0.08);
  width: fit-content;
  min-width: fit-content;

  &:hover {
    filter: brightness(90%);
  }

  @media screen and (max-width: 899px) {
    font-size: 2.5rem;
  }
`;
