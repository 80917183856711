import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { KeyboardEvent, SyntheticEvent, useState } from 'react';
import { AutocompleteChangeReason, AutocompleteRenderGetTagProps, Chip } from '@mui/material';
import AddNewTag from '../../common/AddNewTag';

import { AutocompleteContainer } from "./styles";

export type Tag = {
    text: string;
    color?: string;
};

type Props = {
    type: string;
    tagList: Tag[];
    values: Tag[];
    setValues: any;
    placeholder: string;
};

const clean = (s: string) => {
    return (s.charAt(0).toUpperCase() + s.slice(1)).replace(/\s+/g, ' ').trim()
};

export const normalize = (s: string) => {
	return s.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // Removes accents
        .replace(/\s+/g, ' ').trim() // Removes extra spaces
		.replace(/([^\w]+|\s+)/g, '-') // Replaces space and other characters by hyphen
        .toLowerCase();
}

const repeated = (tagList: Tag[], t: string) => {
    let list = tagList.map(({ text }) => normalize(text));
    return list.includes(normalize(t));
}

const SelectTags = ({ type, tagList, placeholder, values, setValues }: Props) => {
    const [value, setValue] = useState({ text: "" });
    const [showAddNewTag, setShowAddNewTag] = useState(false);
    const [defaultValue] = useState(values);
    const [disable, setDisable] = useState(false);

    const handleRenderTags = (value: Tag[], getTagProps: AutocompleteRenderGetTagProps) => {
        if (disable) return (
            <Chip
                label={value[0].text}
                {...getTagProps({ index: 0 })}
                style={{ backgroundColor: value[0].color }}
            />
        );
        return value.map((option, index) => (
            <Chip
                label={option.text}
                {...getTagProps({ index })}
                style={{ backgroundColor: option.color }}
                deleteIcon={ <CloseIcon sx={{ width: '1.2rem', height: '1.2rem', fill: '#7E7E7E' }} /> }
            />
      ))
    };

    const handleOnChange = (
        event: SyntheticEvent<Element, Event>,
        value: (string | Tag)[],
        reason: AutocompleteChangeReason
    ) => {
        if (type === "gigType" && values.length > 0 && value.length > 0) {
            value.pop();
            return;
        }
        if (reason === "createOption" || reason === "selectOption") {
            let val = value[value.length-1]
            if (typeof val === "string") {
                if (repeated(values, val)) {
                    value.pop();
                } else {
                    let newTag = { text: clean(val) }
                    value[value.length-1] = newTag;
                }
            } else {
                if (repeated(values, val.text)) {
                    value.pop();
                }
            }
        } else if (reason === "removeOption") {
            if (type === "gigType") {
                for (let index = 0; index < 100; index++) {
                    value.pop();
                }
                values = [];
                setValues(values);
                setDisable(false);
                return;
            }

            values.pop();
        }

        setShowAddNewTag(false);

        value.forEach((t, i) => {
            if (typeof t !== 'string') {
                values[i] = t;
            }
        });

        setValues(values);

        if ((reason === "createOption" || reason === "selectOption") && type === "gigType") {
            setDisable(true);
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
        if (type === "gigType" && values.length > 0 && e.key !== "Backspace") {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
        if (type === "gigType" && e.code === "Enter" && e.cancelable) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    return (
        <AutocompleteContainer>
            <Autocomplete
                id="req-access-autocomplete"
                disableClearable
                className={`${type === "gigType" ? "select-tags-input-gigs " : ""}select-tags-input`}
                defaultValue={ defaultValue }
                multiple
                options={ !disable ? tagList : []}
                getOptionLabel={ option => typeof option === "string" ? option : option.text }
                freeSolo
                filterSelectedOptions
                popupIcon={ false }
                renderTags={ handleRenderTags }
                onInputChange={ (event, value) => {
                    if (type === "gigType" && values.length > 0 && value.length > 0) {
                        return;
                    }
                    setValue({ text: clean(value) });
                    if (!disable) {
                        if (value.length > 1) {
                            setShowAddNewTag(true);
                        } else {
                            setShowAddNewTag(false);
                        }
                    }
                }}
                onChange={ handleOnChange }
                renderInput={(params) => (
                    <>
                        <TextField
                            margin="normal"
                            {...params}
                            placeholder={ !disable ? placeholder : ""}
                            onKeyDown={handleKeyDown}
                        />
                        <AddNewTag type={type} show={ showAddNewTag } value={ value } />
                    </>
                )}
            />
        </AutocompleteContainer>
    );
}

export default SelectTags;
